import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'
import MarkdownLink from './CustomLink'

export const PostBody = ({ body }) => {
  return (
    <MDXProvider components={{
        ...components,
        a: (props) => <MarkdownLink {...props} />,
    }}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  )
}
